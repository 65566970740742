import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import * as ROUTES from './Constants/routes';
import Sidebar from './components/Sidebar/Sidebar';
import ChatMonitoring from './Pages/ChatMonitoring/ChatMonitoring';
import UserImageAddition from './Pages/UserImageAddition/UserImageAddition';
import DutyCoin from './Pages/DutyCoin/DutyCoin';
import ChatSite from './Pages/ChatSite/ChatSite';
import NewAssigments from './Pages/NewAssigments/NewAssigments';
import OldAssigments from './Pages/OldAssigments/OldAssigments';
import AdsPage from './Pages/Ads/Ads';
import UserDataEditing from './Pages/UserDataEditing/UserDataEditing';
import CustomerServiceChat from './Pages/CustomerServiceChat/CustomerServiceChat';
import './styles.css';
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import LandingPage from './Pages/LandingPage/LandingPage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebase.config';
import { CustomerChat } from './types/types';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { setChats } from './redux/customerServiceChatSlice';

const router = createBrowserRouter([
  {
    element: <Navbar />,
    children: [
      {
        element: <ProtectedRoutes />,
        children:[
          {
            element: <Sidebar />,
            children: [
              {
                path: ROUTES.LANDING,
                element: <LandingPage />
              },
              {
                path: ROUTES.ADS,
                element: <AdsPage />
              },
              {
                path: ROUTES.CHATMONITORING,
                element: <ChatMonitoring />
              },
              {
                path: ROUTES.CHATSITE,
                element: <ChatSite />
              },
              {
                path: ROUTES.CUSTOMERSERVICECHAT,
                element: <CustomerServiceChat />
              },
              {
                path: ROUTES.DUTYCOIN,
                element: <DutyCoin />
              },
              {
                path: ROUTES.NEWASSIGNMENTS,
                element: <NewAssigments />
              },
              {
                path: ROUTES.OLDASSIGNMENTS,
                element: <OldAssigments />
              },
              {
                path: ROUTES.USERDATAEDITING,
                element: <UserDataEditing />
              },
              {
                path: ROUTES.USERIMAGEADDITION,
                element: <UserImageAddition />
              }
            ]
          }
        ]
      }
    ]
  }
]);

function App() {

  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      return;
    }
    (async () => {
      const q = query(collection(db, 'CustomerService'));
      const observer = onSnapshot(q, (querySnapshot) => {
        const chatArray: CustomerChat[] = [];
        querySnapshot.forEach((doc) => {
          chatArray.push(doc.data() as CustomerChat);
        });
        dispatch(setChats(chatArray));
      });
      return () => observer();
    })().catch(err => console.log(err));
  }, [user]);

  return (
    <main>
      <RouterProvider router={router} />
    </main>
  );
}

export default App;
