import React, { useState } from 'react';
import { IReport } from '../../types/types';

interface ReportHandlerProps {
  reportsArray: Array<IReport>,
  setHandledReports: (reports: Array<IReport>) => void
}

const ReportHandler = ({reportsArray, setHandledReports}: ReportHandlerProps) => {
  const [reports, setReports] = useState<Array<IReport>>(reportsArray);
  const [index, setIndex] = useState(0);

  const keepReport = () => {
    setIndex(index + 1);
  };
  const deleteReport = () => {
    const newArray = [...reports];
    newArray.splice(index, 1);
    setReports(newArray);
    setHandledReports(newArray);
  };

  return (
    <div style={{'paddingBottom': '10px'}}>
      {reports.length === 1 && <p style={{'color': 'red', 'fontWeight': 'bold'}}>1 raportti</p>}
      {reports.length > 1 && <p style={{'color': 'red', 'fontWeight': 'bold'}}>{reports.length} raporttia</p>}
      {index < reports.length && 
      <div>
        <p style={{'backgroundColor': '#ffdede'}}>&apos;{reports[index].reason}&apos;</p>
        <button className='acceptReportButton' onClick={keepReport}>Aiheellinen raportti</button>
        <button className='deleteReportButton' onClick={deleteReport}>Poista raportti</button>
      </div>
      }
    </div>
  );
};



export default ReportHandler;