import React, { useState, useEffect } from 'react';
import {
  collection,
  query, where, doc, updateDoc } from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase.config';
import Spinner from '../Spinner/Spinner';
import { IUser } from '../../types/types';
import ReactSelect from 'react-select';

/**
 * Reviewer voi lisätä haluamalleen käyttäjälle DutyCoineja 
 */

const Gift = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [selected, setSelected] = useState<Array<{value: string, label: string}>>([]);
  const [amount, setAmount] = useState(0);

  const [value, loading] = useCollectionOnce(
    query(collection(db, 'Users'), where('identified', '==', true))
  );

  useEffect(() => {
    const tempUsers: IUser[] = [];
    if (value) {
      value.docs.map((doc) => {
        tempUsers.push(doc.data() as IUser);
      });
      setUsers(tempUsers);
    }
  }, [value]);


  const amountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(parseFloat(event.target.value));
  };

  const addCoins = async () => {
    if (!amount || selected.length == 0) return;

    for (const item of selected) {
      const docRef = doc(db, 'Users', item.value);
      const user = users.find(u => u.uid == item.value);
      if (!user) {
        continue;
      }
      await updateDoc(docRef, {
        gift: user.gift + amount,
      }).catch(err => {
        window.alert('Virhe lisäyksessä' + err.errorMessage);

      });
    }
    window.alert('Lisäys tehty');
  };

  const usersChanged = (values: any) => {
    setSelected(values);
  };

  const userOptions = users.map((item) => {
    const name = item.generalInfo.firstName + ' ' + item.generalInfo.lastName;
    const email = item.generalInfo.email;
    return {
      value: item.uid, label: name + ' - ' + email + '(DutyCoinds: ' + item.gift + ')'
    };
  });


  return (
    <div className='addDutyCoins'>
      <h2>Lisää DutyCoineja</h2>
      {loading ? <Spinner size={20} /> :
        <div className='user-list'>
          <ReactSelect
            options={userOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={usersChanged}
            value={selected}
          />
          <input className='input-coins' type='number' value={amount} onChange={amountChange} />
          {(amount && selected.length > 0) ? <button onClick={addCoins}>Lisää DutyCoineja</button> : null}
        </div>
      }
    </div>
  );
};

export default Gift;