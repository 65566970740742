import React from 'react';
import CustomerService from '../../components/CustomerService/CustomerService';

const CustomerServiceChat: React.FC = () => {
  return (
    <>

      <CustomerService/>
    </>
  );
};

export default CustomerServiceChat;
