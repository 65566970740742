import React from 'react';
import Gift from '../../components/Gift/Gift';

const DutyCoin: React.FC = () => {
  return (
    <>
      <Gift/>
    </>
  );
};

export default DutyCoin;