import React, {useState } from 'react';
import { Duty, IDutyImage, IReport } from '../../types/types';
import Modal from 'react-modal';
import locationSVG from '../../assets/location.svg';
import toolsSVG from '../../assets/tools.svg';
import ReportHandler from '../ReportHandler/ReportHandler';
import DropdownList from '../DropdownList/DropdownList';
interface OnRejectParams {
  duty: Duty,
  reports: IReport[],
  rejectedFields: any,
  rejectDetails: string
}
interface ReviewModalProps {
  duty: Duty,
  isOpen: boolean,
  closeModal: () => void;
  onAccept: (duty: Duty) => void;
  onReject: ({duty, reports, rejectedFields, rejectDetails}: OnRejectParams) => void;
}
const modalHeight = window.innerHeight - 100;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '900px',
    maxHeight: modalHeight
  },
  overlay: {
    backgroundColor: 'rgb(0, 0, 0, 0.6)'
  }
};
const ReviewModal = ( {duty, isOpen, closeModal, onAccept, onReject}: ReviewModalProps) => {
  const [dutyRejected, setDutyRejected] = useState<boolean>();
  const [rejectDetails, setRejectDetails] = useState<string>('');
  const [reports, setReports] = useState<Array<IReport>>(duty.reports ?? []);
  const [rejectedFields, setRejectedFields] = useState({
    title: {
      rejected: false,
      reason: ''
    },
    desc: {
      rejected: false,
      reason: ''
    },
    address: {
      rejected: false,
      reason: ''
    },
    city: {
      rejected: false,
      reason: ''
    },
    tools: {
      rejected: false,
      reason: ''
    },
    images: {
      rejected: false,
      reason: ''
    },
  });

  const onModalClose = () => {
    closeModal();
  };
  const onAcceptDuty = () => {
    console.log('Hyväksytty ', duty.doc);
    onAccept(duty);
  };
  const onRejectDuty = () => {
    setDutyRejected(true);
  };
  const confirmReject = () => {
    console.log('Hylätty ', duty.doc);
    onReject({duty, reports, rejectedFields, rejectDetails});
    setDutyRejected(false);
    setRejectDetails('');
  };
  const cancelReject = () => {
    setDutyRejected(false);
    setRejectDetails('');
  };
  const setHandledReports = (handledReports: Array<IReport>) => {
    setReports(handledReports);
  };
  const handleRejectedFieldsChange = (event:any) => {
    const obj:any = {...rejectedFields};
    const field = event.target.id;
    obj[field].rejected = event.target.checked;
    if (obj[field].rejected === true) {
      obj[field].reason = 'Epäasiallinen';
    }
    if (obj[field].rejected === false) {
      obj[field].reason = '';
    }
    setRejectedFields(obj);
  };
  const handleRejectReasonChange = (event: any) => {
    const obj:any = {...rejectedFields};
    const field = event.target.id;
    obj[field].reason = event.target.value;
    setRejectedFields(obj);
  };
  const handleTextAreaChange = (event: any) => {
    setRejectDetails(event.target.value);
  };

  console.log('Duty', duty);
  return (
    <div>
      {duty &&
      <Modal
        isOpen={isOpen}
        onRequestClose={onModalClose}
        style={customStyles}
        ariaHideApp={false}
      >
        
        <button style={{'marginBottom': '10px'}} onClick={onModalClose}>Close</button>
        {dutyRejected 
          ? 
          <div>
            <h3 style={{'marginBottom': '5px'}}>Perustele dutyn hylkäys</h3>
            <div>
              <p>Raportit:</p>
              <div style={{'display': 'flex', 'backgroundColor': '#ffdede'}}>
                {reports?.length > 0 && reports.map((i, index) => (
                  <p key={index}>{i.reason} |&nbsp;</p>
                ))}
              </div>
            </div>
            <div className='duty-data reject-fields'>
              <input type='checkbox' id='title' checked={rejectedFields.title.rejected} onChange={handleRejectedFieldsChange}/>
              <DropdownList field='title' rejectedFields={rejectedFields} handleRejectReasonChange={handleRejectReasonChange} />
              Otsikko: {duty.title}
            </div> 
            <div className='duty-data reject-fields'>
              <input type='checkbox' id='desc' checked={rejectedFields.desc.rejected} onChange={handleRejectedFieldsChange}/>
              <DropdownList field='desc' rejectedFields={rejectedFields} handleRejectReasonChange={handleRejectReasonChange} />
              Kuvaus: {duty.desc}
            </div>
            <div className='duty-data reject-fields'>
              <input type='checkbox' id='address' checked={rejectedFields.address.rejected} onChange={handleRejectedFieldsChange}/>
              <DropdownList field='address' rejectedFields={rejectedFields} handleRejectReasonChange={handleRejectReasonChange} />
              Katuosoite: {duty.address}
            </div>
            <div className='duty-data reject-fields'>
              <input type='checkbox' id='city' checked={rejectedFields.city.rejected} onChange={handleRejectedFieldsChange}/>
              <DropdownList field='city' rejectedFields={rejectedFields} handleRejectReasonChange={handleRejectReasonChange} />
              Kaupunki: {duty.city}
            </div>
            <div className='duty-data reject-fields'>
              <input type='checkbox' id='tools' checked={rejectedFields.tools.rejected} onChange={handleRejectedFieldsChange}/>
              <DropdownList field='tools' rejectedFields={rejectedFields} handleRejectReasonChange={handleRejectReasonChange} />
              Työkalut: {duty.tools ? duty.tools : '-'}
            </div>
            <div className='duty-data reject-fields'>
              <input type='checkbox' id='images' checked={rejectedFields.images.rejected} onChange={handleRejectedFieldsChange}/>
              <DropdownList field='images' rejectedFields={rejectedFields} handleRejectReasonChange={handleRejectReasonChange} />
              Kuvat:
            </div>
            <div>
              {duty.images && duty.images.map((i:IDutyImage, index:number) => (
                <img src={i.url} style={{'maxWidth': '150px', 'maxHeight': '100px'}} key={index} />
              ))}
            </div>
            <textarea className='reject-text' value={rejectDetails} onChange={(e) => handleTextAreaChange(e)} cols={40} rows={3} placeholder={'Muu syy tai lisätietoja (vapaaehtoinen)'} />
            <div>
              <button onClick={confirmReject} className='accept-button'>Reject</button>
              <button onClick={cancelReject} className='reject-button'>Go back</button>
            </div>
          </div>  

          :

          <div>
            <h3>{duty.title} - {duty.price} €</h3> 
            <div className='duty-desc duty-data'>{duty.desc}</div>
            <div className='duty-data'><img src={locationSVG} width={10} /> {duty.address}
              <p>{duty.city}</p>
            </div>
            <div className='duty-data'><img src={toolsSVG} width={10} /> {duty.tools ? duty.tools : '-'}</div>

            {reports && (
              <ReportHandler reportsArray={reports} setHandledReports={setHandledReports} />
            )
            }
            <div>
              {duty.images && duty.images.map((i:IDutyImage, index:number) => (
                <img src={i.url} style={{'maxWidth': '150px', 'maxHeight': '100px'}} key={index} />
              ))}
            </div>
            <div style={{'marginTop': '20px', 'display': 'flex'}}>
              <div style={{'width': '150px'}}>
                {reports.length === 0 &&
                  <button onClick={onAcceptDuty} className='accept-button'>
                    Accept
                  </button>
                }
              </div>
              <div>
                <button onClick={onRejectDuty} className='reject-button'>
                  Reject
                </button>
              </div>
            </div>
          </div>
        }
        
      </Modal>
      }
    </div>

  );

};

export default ReviewModal;