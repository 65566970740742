import React from 'react';
import { auth } from '../../firebase.config';
import { Outlet } from 'react-router-dom';
import Login from '../../Pages/Login/Login';
import { useAuthState } from 'react-firebase-hooks/auth';

const ProtectedRoutes = () => {
  const [user, loading, error] = useAuthState(auth);
  if (user) {
    return (
      <Outlet />
    );
  }
  if (loading) {
    return (
      <div>loading</div>
    );
  }
  if (error) {
    return (
      <div>hupsista ! jokin meni vikaan</div>
    );
  }
  return (
    <Login />
  );

};

export default ProtectedRoutes;