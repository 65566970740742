import React, { useRef, useLayoutEffect} from 'react';
import { CustomerChat, IMessage } from '../../../types/types';
import Message from './Message/Message';
import SendMessage from './SendMessage/SendMessage';
import { db } from '../../../firebase.config';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';

interface ChatWindowProps {
  chat: CustomerChat
}
const ChatWindow = ({chat}: ChatWindowProps) => {
  const chatContainerRef= useRef<any>();

  const handleSendMessage = async (message: string) => {
    const messageData: IMessage = {
      message: message,
      picture: '',
      read: 0,
      timestamp: Date.now(),
      user: 'Duty'
    };

    const docRef = doc(db, 'CustomerService', chat.customerId);
    await updateDoc(docRef, {
      messages: arrayUnion(messageData)
    }).catch(err => console.log(err));

  };
  const scrollHandler = () => {
    const chatContainer = chatContainerRef.current;
    chatContainer?.scrollTo({
      top: chatContainer.scrollHeight,
      behavior: 'instant'
    });
  };
  useLayoutEffect(() => {
    scrollHandler();
  }, [chat.messages]);

  return (
    <div className='chat-container'>
      <p style={{paddingBottom: 10}}>Linkin lähetys: &lt;link&gt;https://dutyapp.fi/&lt;/link&gt;</p>
      <div className='chat-window' ref={chatContainerRef}>
        <h4 style={{textAlign: 'center', paddingBottom: 15}}>
          Chat with {chat.customerName}
        </h4>
        {chat.messages.map((msg, index) => {
          const messageSentBy = msg.user === chat.customerId ? chat.customerName : 'Duty Asiakaspalvelu';
          return (
            <div key={index}>
              <Message message={msg} name={messageSentBy} altBgColor={messageSentBy==='Duty Asiakaspalvelu'} key={index} />
            </div>
          );
        })}
      </div>
      <SendMessage onSubmit={handleSendMessage} />
    </div>
  );
};

export default ChatWindow;