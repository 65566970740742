import React, { useEffect, useState } from 'react';
import { collection, query, where, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase.config';
import DutyListItem from '../DutyListItem/DutyListItem';
import { Duty, IReport } from '../../types/types';
import ReviewModal from '../ReviewModal/ReviewModal';

interface onRejectParams {
  duty: Duty,
  reports: IReport[],
  rejectedFields: any,
  rejectDetails: string
}

interface DutyListProps {
  filterTimestamp?: number;
}

const DutyList: React.FC<DutyListProps> = ({ filterTimestamp }) => {
  const [duties, setDuties] = useState<Array<Duty>|undefined>();

  
  const dutyQuery = filterTimestamp ?
    query(collection(db, 'Duties'), where('timestamp', '>=', filterTimestamp)) :
    query(collection(db, 'Duties'));

  const [value, loading] = useCollectionOnce(dutyQuery);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeDuty, setActiveDuty] = useState<Duty|null>(null);
  const [activeDutyIndex, setActiveDutyIndex] = useState<number>(0);

  const openModal = (duty: Duty, index: number) => {
    setIsOpen(true);
    setActiveDuty(duty);
    setActiveDutyIndex(index);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveDuty(null);
  };

  const onAccept = async (duty: Duty) => {
    const docRef = doc(db, 'Duties', duty.doc);
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const endDate = date.getTime() / 1000;
    setNewDuty();
  };

  const onReject = async ({duty, reports, rejectedFields, rejectDetails}: onRejectParams) => {
    if (duty.status !== 'active') { 
      return;
    }
    const docRef = doc(db, 'Duties', duty.doc);
    await updateDoc(docRef, {
      status: 'rejected',
      reports: reports
    });
    await setDoc(doc(db, 'Rejects', duty.doc), {
      reports: reports,
      fields: rejectedFields,
      details: rejectDetails
    });
    setNewDuty();
  };

  const setNewDuty = () => {
    if (duties) {
      const dutiesCopy = [...duties];
      dutiesCopy.splice(activeDutyIndex, 1);
      setDuties(dutiesCopy);
    }
  };

  useEffect(() => {
    if (value) {
      setDuties(value.docs.map(doc => doc.data() as Duty));
    }
  }, [value]);

  return (
    <div className='duties-container'>
      {activeDuty &&
        <ReviewModal
          closeModal={closeModal}
          isOpen={modalIsOpen}
          onAccept={onAccept}
          onReject={onReject}
          duty={activeDuty}/>
      }
      {loading && <p>Loading..</p>}
      <table>
        <thead>
          <tr>
            <th className='table-title'>Title</th>
            <th className='table-title'>Published</th>
            <th className='table-title'>Price</th>
          </tr>
        </thead>
        <tbody>
          {duties && duties.map((i:Duty, index:number) => (
            <DutyListItem 
              onClick={() => openModal(i, index)}
              duty={i}
              key={i.doc}
              index={index}/>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DutyList;
