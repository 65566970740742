import React from 'react';

const UserImageAddition: React.FC = () => {
  return (
    <>
      <h1>KäyttäjäKuvienLisäys</h1>
      <p>Nappi tähän</p>
    </>
  );
};

export default UserImageAddition;
