import React, { useState, useEffect } from 'react';
import styles from './Ads.module.css';
import Spinner from '../Spinner/Spinner';
import AdListItem from './AdListItem/AdListItem';
import AdModal from './AdModal/AdModal';
import { Ad } from '../../types/types';
import { collection, query, where } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase.config';

const Ads = () => {
  const [ads, setAds] = useState<Array<Ad>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [latestWeek, setLatestWeek] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeAd, setActiveAd] = useState<Ad|null>(null);
  const [activeAdIndex, setActiveAdIndex] = useState<number>(0);
  
  const [coll] = useCollection(
    query(collection(db, 'Ads'), where('timestamp', '>=', latestWeek))
  );

  useEffect(() => {
    setIsLoading(true);
    if (coll) {
      const adsArray: Ad[] = [];
      coll.docs.map((doc) => {
        adsArray.push(doc.data() as Ad);
      });
      setAds(adsArray);
      setIsLoading(false);
    }
  }, [coll]);

  useEffect(() => {
    updateTime();
  }, []);

  const updateTime = () => {
    //Ad.timestamp is in seconds and Date.now() is in milliseconds. There are 604800 seconds in a week.
    setLatestWeek((Date.now()/1000) - 604800);
  };

  const openModal = (ad: Ad, index: number) => {
    setIsOpen(true);
    setActiveAd(ad);
    setActiveAdIndex(index);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveAd(null);
  };

  return (
    <div className='page-container'>
      <header className='header'>
        <h2 className='page-title'>Mainokset</h2>
      </header>
      {activeAd &&
        <AdModal
          ad={activeAd}
          isOpen={modalIsOpen}
          closeModal={closeModal}
        />
      }
      {isLoading 
        ? <Spinner size={20} />
        : !ads || ads.length === 0
          ? <p>Ei mainoksia</p>
          : <div className={styles.adsContainer}>
            {ads.sort((a, b) => {
              return b.timestamp - a.timestamp;
            }).map((ad: Ad, index: number) => (
              <div className={styles.adContainer} key={ad.doc}>
                <AdListItem
                  ad={ad}
                  onClick={() => openModal(ad, index)} />
              </div>
            ))}
          </div>
      }
    </div>
  );
};

export default Ads;