import React from 'react';
import NewDutyList from '../../components/NewDutyList/NewDutyList';

const NewAssignments: React.FC = () => {
  const now = new Date();
  const oneWeekAgoSeconds = Math.floor(now.getTime() / 1000) - 7 * 24 * 3600; 

  return (
    <>
      <NewDutyList filterTimestamp={oneWeekAgoSeconds}/>
    </>
  );
};

export default NewAssignments;
