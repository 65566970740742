import React, { useState, useEffect } from 'react';
import { db } from '../../firebase.config';
import { CustomerChat, IMessage, IUser } from '../../types/types';
import { doc, updateDoc, collection, query, getDocs } from 'firebase/firestore';
import ChatWindow from './ChatWindow/ChatWindow';
import GlobalMessage from './GlobalMessage/GlobalMessage';
import MultiMessage from './MultiMessage/MultiMessage';
import UserInfo from '../UserInfo/UserInfo';
import { useSelector } from 'react-redux';
import { selectCustomerServiceChats } from '../../redux/customerServiceChatSlice';
import { useCollection } from 'react-firebase-hooks/firestore';
import { getDate, getTime } from '../../utils/time';


const CustomerService: React.FC = () => {
  const [selectedChat, setSelectedChat] = useState<CustomerChat | null>();
  const [globalMessage, setGlobalMessage] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [multiMessage, setMultiMessage] = useState(false);
  const [selectedMulti, setSelectedMulti] = useState<CustomerChat[]>([]);
  const [filter, setFilter] = useState<string>('');
  const chats = useSelector(selectCustomerServiceChats);
  const [users, setUsers] = useState<IUser[]>([]);
  const [coll] = useCollection(query(collection(db, 'Users')));

  useEffect(() => {
    const updatedChat = chats.find(
      (chat) => chat.customerId === selectedChat?.customerId
    );
    setSelectedChat(updatedChat);
  }, [chats]);


  useEffect(() => {
    if (coll) {
      const usersArray: IUser[] = [];
      coll.docs.map((doc) => {
        usersArray.push(doc.data() as IUser);
      });
      setUsers(usersArray);
    }
  }, [coll]);

  const openGlobalMessage = () => {
    setSelectedChat(null);
    setGlobalMessage(true);
  };
  const closeGlobalMessage = () => {
    setGlobalMessage(false);
  };
  const toggleMultiMessage = () => {
    setSelectedChat(null);
    setSelectedMulti([]);
    setMultiMessage(!multiMessage);
  };
  const handleSelectChat = (chat: CustomerChat) => {
    if (multiMessage) {
      handleSelectChatMulti(chat);
    } else {
      closeGlobalMessage();
      setSelectedChat(chat);
      markMessagesSeen(chat);
    }
  };
  const handleSelectChatMulti = (chat: CustomerChat) => {
    if (isSelected(chat)) {
      unselectMulti(chat.customerId);
    } else {
      setSelectedMulti((prevChats) => [...prevChats, chat]);
    }
  };
  const isSelected = (chat: CustomerChat) => {
    return selectedMulti.some((c) => c.customerId === chat.customerId);
  };
  const unselectMulti = (customerIdToRemove: string) => {
    setSelectedMulti((prevChats) =>
      prevChats.filter((chat) => chat.customerId !== customerIdToRemove)
    );
  };
  const selectAll = () => {
    if (selectedMulti.every(c => filteredChats.includes(c)) && filteredChats.every(d => selectedMulti.includes(d))) {
      setSelectedMulti([]);
    } else {
      setSelectedMulti(filteredChats);

    }
  };
  const updateMessages = async (messages: IMessage[], customerId: string) => {
    const docRef = doc(db, 'CustomerService', customerId);
    await updateDoc(docRef, {
      messages: messages,
    }).catch((err) => console.log(err));
  };
  const markMessagesSeen = (chat: CustomerChat) => {
    let updated = false;
    const messages = [...chat.messages];
    messages.forEach((message: IMessage, index: number) => {
      if (message.read === 0 && message.user === chat.customerId) {
        updated = true;
        messages[index] = { ...message, read: Date.now() };
      }
    });
    if (updated) {
      updateMessages(messages, chat.customerId);
    }
  };
  const filterFunction = (chat: CustomerChat) => {
    const user = users.find(user => user.uid === chat.customerId);

    const isFilterMatch =
      filter === '' ||
      (filter === 'authenticated' && user?.identified) || 
      (filter === 'unauthenticated' && !user?.identified);

    const isSearchMatch =
      searchQuery === '' ||
      chat.customerName?.toLowerCase().includes(searchQuery);

    return isFilterMatch && isSearchMatch;
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };

  const filteredChats: CustomerChat[] = chats.filter(filterFunction);

  return (
    <div className='page-container'>
      <header className='header'>
        <h2 className='page-title'>Asiakaspalvelu Chat</h2>
      </header>
      <div className='flex-row'>
        <div className='flex-column' style={{ width: '300px' }}>
          <div>
            <button style={{ padding: 4, marginRight: 10, marginBottom: 10, width: 145 }} onClick={toggleMultiMessage}>
              Viesti monelle
            </button>
            {multiMessage && (
              <button style={{ padding: 4, marginBottom: 10, width: 145 }} onClick={selectAll}>
                Valitse kaikki
              </button>
            )}
            <select
              onChange={handleFilterChange}
              style={{ padding: 4, marginBottom: 10, width: 300 }}
            >
              <option value=''>Ei filtteriä</option>
              <option value='authenticated'>Tunnistautuneet käyttäjät</option>
              <option value='unauthenticated'>Tunnistautumattomat käyttäjät</option>
            </select>
            <input
              type='text'
              placeholder='Etsi nimellä...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ padding: 4, marginBottom: 10, width: 290 }}
            />
          </div>
          {filteredChats
            .sort((a, b) => {
              const latestMessageA = a.messages.length > 0 ? a.messages[a.messages.length - 1].timestamp : 0;
              const latestMessageB = b.messages.length > 0 ? b.messages[b.messages.length - 1].timestamp : 0;
              return latestMessageB - latestMessageA;
            })
            .map((chat, index) => {
              const latestMessage =
                chat.messages.length > 0 ? chat.messages[chat.messages.length - 1].timestamp : 0;
              const formattedDate = getDate(latestMessage);
              const formattedTime = getTime(latestMessage);
              return (
                <div key={index}>
                  <div
                    onClick={() => handleSelectChat(chat)}
                    className={isSelected(chat) ? 'selected-row' : selectedChat === chat ? 'selected-row' : index % 2 === 0 ? 'gray-row' : 'white-row'}
                    style={{
                      width: '300px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>{chat.customerName ? chat.customerName : '-'}</div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '14px', color: 'black' }}>
                      {chat.messages.some(
                        (message) => message.user === chat.customerId && !message.read
                      ) && (
                        <div
                          style={{
                            fontWeight: '700', marginLeft: 10,
                            backgroundColor: 'yellow',
                            padding: '2px 8px',
                            borderRadius: '4px',
                          }}
                        >
                          UUSI VIESTI!
                        </div>
                      )}
                    </div>
                    <div className='chat-timestamp'>
                      {formattedDate} {formattedTime}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {multiMessage ? (
          <MultiMessage
            setCancel={() => toggleMultiMessage()}
            selectedUsers={selectedMulti}
            unselectUser={unselectMulti}
          />
        ) : selectedChat ? (
          <ChatWindow chat={selectedChat} key={selectedChat.messages.length} />
        ) : null}
        {selectedChat && <UserInfo uid={selectedChat.customerId} boxHeight='515px' />}
      </div>
    </div>
  );
};
export default CustomerService;