import React from 'react';
import { IMessage } from '../../../../types/types';
import { getDate, getTime } from '../../../../utils/time';
interface MessageProps {
  message: IMessage,
  name: string,
  altBgColor: boolean
}
const Message = ({message, name, altBgColor}: MessageProps) => {

  return (
    <div className='message-container' style={altBgColor ? {backgroundColor: '#fffb91'} : {}}>
      <div className='message-sender'>
        {name}:
      </div>
      <div>
        {message.picture &&
        <div>
          <img src={message.picture} alt='kuva' width={335} height={'100%'}/>
        </div>
        }
        {message.message}
        <div className='chat-timestamp' style={{ color: 'gray', paddingTop: 5 }}>
          {getDate(message.timestamp)} {getTime(message.timestamp)}
        </div>
      </div>
    </div>
  );
};

export default Message;