import React from 'react';
import ChatMonitor from '../../components/ChatMonitor/ChatMonitor';

const ChatMonitoring: React.FC = () => {
  return (
    <>
      <ChatMonitor />
    </>
  );
};

export default ChatMonitoring;