import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styles from './ImageCarousel.module.css';
import { IDutyImage } from '../../types/types';
import placeholderImage from '../../assets/Logo_duty.png';

interface ImageCarouselProps {
  images: IDutyImage[]
}
const ImageCarousel = ({images}: ImageCarouselProps) => {
  const imagesArray = images.length ? images : [{url: placeholderImage, name: '-', type: '-', valid: false, saved: false,}];

  return (
    <div>
      <Carousel 
        className={styles.carousel}
        showStatus={false}
        thumbWidth={60}
        swipeable={false}
      >
        {imagesArray.map((image, index) => (
          <div key={index} className={styles.imageContainer}>
            <img className={styles.image} src={image.url} />
            <div className='legend'>
              <div>Name: {image.name ? image.name : '-'}<span style={{marginLeft: '0.5rem'}}></span> Type: {image.type ? image.type : '-'}<span style={{marginLeft: '0.5rem'}}></span> Valid: {image.valid ? 'True' : 'False'}<span style={{marginLeft: '0.5rem'}}></span> Saved: {image.saved ? 'True' : 'False'}</div>
              <div>URL: {image.url}</div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;