import React from 'react';
import Ads from '../../components/Ads/Ads';

const AdsPage: React.FC = () => {
  return (
    <>
      <Ads />
    </>
  );
};

export default AdsPage;