import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCustomerServiceChats } from '../../redux/customerServiceChatSlice';

const Sidebar = () => {
  const [expanded, setExpanded] = useState(true);
  const [locked, setLocked] = useState(true);
  const [csChatAlert, setCsChatAlert] = useState(false);
  const cschats = useSelector(selectCustomerServiceChats);

  function handleEnter() {
    if (!locked)
      setExpanded(true);
  }

  function handleLeave() {
    if (!locked)
      setExpanded(false);
  }

  function toggleLock() {
    setLocked(!locked);
  }

  useEffect(() => {
    const hasUnreadMessages = cschats.some(chat =>
      chat.messages.some(message => message.user === chat.customerId && !message.read)
    );
    setCsChatAlert(hasUnreadMessages);
  }, [cschats]);

  return (
    <div style={{display: 'flex'}}>
      <aside className='h-screen' onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
        <nav className={expanded ? 'sidebarNavEx' : 'sidebarNav'}>
          <div className='sidebar'>
            <div className='sidebarText'>
              <img style={{marginLeft:'15px',marginTop:'10px', padding:'5px', cursor:'pointer'}} onClick={toggleLock} src={locked ? require('../../assets/LockPH.png') : require('../../assets/UnlockPH.png')}></img>
            </div>
            <ul className={expanded ? 'sidebarListEx' : 'sidebarList'}>
              <li>
                <Link className='sidebarText' to={'Ads'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  Mainokset
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'ChatMonitoring'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  Chatin valvonta
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'ChatSite'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  ChatSite
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'CustomerServiceChat'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  {csChatAlert &&
                    <div className='alert'/>}
                  Asiakaspalvelu Chat
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'DutyCoin'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  DutyCoins-lisäys
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'NewAssigments'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  Uudet tehtävät
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'OldAssigments'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  Duty review 
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'UserDataEditing'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  Käyttäjätiedot
                </Link>
              </li>
              <li>
                <Link className='sidebarText' to={'UserImageAddition'}>
                  <img className='sidebarIcon' src={require('../../assets/SymbolPH.png')} />
                  Käyttäjäkuvat
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </aside>
      <Outlet />
    </div>
  );
};
export default Sidebar;