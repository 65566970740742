import React, { useState } from 'react';

interface SendMessageProps {
  onSubmit: (message: string) => void;
}

const SendMessage = ({onSubmit}: SendMessageProps) => {

  const [message, setMessage] = useState('');

  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    if (message.trim() !== '') {
      onSubmit(message);
      setMessage('');
    }
  };

  return (
    <div style={{marginTop: 10}}>
      <textarea
        rows={4}
        cols={50}
        placeholder="Kirjoita viestisi..."
        value={message}
        onChange={handleChange}
        style={{height: 60}}
      />
      <div>
        <button style={{padding: 10, marginTop: 5}} onClick={handleSubmit}>Lähetä</button>
      </div>
    </div>
  );
};

export default SendMessage;