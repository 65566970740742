import React, { useState } from 'react';
import { functions } from '../../firebase.config';
import { httpsCallable } from 'firebase/functions';

const VerifyUser = () => {
  const [user, setUser] = useState('');

  const setUid = (event: any) => {
    setUser(event.target.value);
  };

  const verifyUser = async () => {
    if (user) {
      await httpsCallable(functions, 'verifyUserEmail')(user).then(res => {
        console.log(res);
        window.alert('Onnistui');
        setUser('');
      }).catch(err => {
        console.error(err);
        window.alert('Jotain meni pieleen');
      });
    }

  };

  return (
    <div style={{ display: 'flex', width: 300, flexDirection: 'column'}}>
      <h3>Aseta käyttäjän sähköposti verifioiduksi</h3>
      <label>Käyttäjän uuid</label>
      <input type='text' onChange={setUid} value={user} />
      <button onClick={verifyUser}>Verifioi sähköposti</button>
    </div>
  );
};

export default VerifyUser;