import React, { useEffect, useState } from 'react';
import { collection, query, where, doc, updateDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase.config';
import DutyListItem from '../DutyListItem/DutyListItem';
import { Duty, IReport } from '../../types/types';
import ReviewModal from '../ReviewModal/ReviewModal';

interface onRejectParams {
  duty: Duty,
  reports: IReport[],
  rejectedFields: any,
  rejectDetails: string
}

const DutyList = () => {
  
  const [duties, setDuties] = useState<Array<Duty>|undefined>();
  const [value0, loading0] = useCollectionOnce(
    query(collection(db, 'Duties'), where('status', '==', 'review'))
  );
  const [value1, loading1] = useCollectionOnce(
    query(collection(db, 'Duties'), where('reports', '!=', []))
  );
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeDuty, setActiveDuty] = useState<Duty|null>(null);
  const [activeDutyIndex, setActiveDutyIndex] = useState<number>(0);

  const openModal = (duty: Duty, index: number) => {
    setIsOpen(true);
    setActiveDuty(duty);
    setActiveDutyIndex(index);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveDuty(null);
  };

  const onAccept = async (duty: Duty) => {
    const docRef = doc(db, 'Duties', duty.doc);
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const endDate = date.getTime() / 1000;

    await updateDoc(docRef, {
      status: 'active',
      reports: [],
      start: Math.floor(new Date().getTime() / 1000),
      imageReview: false,
      end: endDate
    });
    await deleteDoc(doc(db, 'Rejects', duty.doc));
    setNewDuty();
  };

  const onReject = async ({duty, reports, rejectedFields, rejectDetails}: onRejectParams) => {
    const docRef = doc(db, 'Duties', duty.doc);
    await updateDoc(docRef, {
      status: 'rejected',
      reports: reports
    });
    const rejectedFieldsArray = Object.entries(rejectedFields)
      .map(([key, value]: any) => {
        if (!value.rejected) return;
        const obj = {
          [key]: value.reason,
        };
        return obj;
      }).filter(item => item !== undefined);
    await setDoc(doc(db, 'Rejects', duty.doc), {
      reports: reports,
      fields: rejectedFieldsArray,
      details: rejectDetails
    });
    setNewDuty();
  };

  const setNewDuty = () => {
    if (duties) {
      const dutiesCopy = [...duties];
      dutiesCopy.splice(activeDutyIndex, 1);
      setDuties(dutiesCopy);
    }
  };

  useEffect(() => {
    if (value0 && value1) {
      const tmpArray: Duty[] = [];
      value0.docs.map((doc) => {
        tmpArray.push(doc.data() as Duty);
      });
      value1.docs.map((doc) => {
        const duty = doc.data() as Duty;
        if (tmpArray.find(i => i.doc === duty.doc)) return;
        tmpArray.push(doc.data() as Duty);
      });
      setDuties(tmpArray);
    }
  }, [value0, value1]);

  useEffect(() => {
    if (activeDuty && duties) {
      setActiveDuty(duties[0]);
      setActiveDutyIndex(0);
    }
  }, [duties]);

  return (
    <div className='duties-container'>
      {activeDuty &&
        <ReviewModal
          closeModal={closeModal}
          isOpen={modalIsOpen}
          onAccept={onAccept}
          onReject={onReject}
          duty={activeDuty}/>
      }
      {(loading0 || loading1) && <p>Loading..</p>}
      <table>
        <thead>
          <tr>
            <th className='table-title'>Title</th>
            <th className='table-title'>Published</th>
            <th className='table-title'>Price</th>
          </tr>
        </thead>
        <tbody>
          {duties && duties.map((i:Duty, index:number) => (
            <DutyListItem 
              onClick={() => openModal(i, index)}
              duty={i}
              key={i.doc}
              index={index}/>
          ))}
        </tbody>
      </table>
    </div>
  );

};

export default DutyList;