import React, { useState, useEffect } from 'react';
import { IChat } from '../../types/types';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, query } from 'firebase/firestore';
import { db } from '../../firebase.config';
import ChatList from './ChatList/ChatList';
import { getDate, getTime } from '../../utils/time';

const ChatMonitor = () => {
  const [chats, setChats] = useState<Array<IChat>>([]);
  const [selectedChatGroup, setSelectedChatGroup] = useState<Array<IChat>>([]);
  const [latestWeek, setLatestWeek] = useState<number>(0);
  const [coll] = useCollection(
    query(collection(db, 'Chat'))
  );

  useEffect(() => {
    if (coll) {
      const chatsArray: IChat[] = [];
      coll.docs.map((doc) => {
        chatsArray.push(doc.data() as IChat);
      });

      if (chatsArray.length > 0) {
        const filteredChats = chatsArray.filter((chat) => {
          const filteredMessages = chat.messages?.filter(
            (message) => message.timestamp >= latestWeek
          );
          return filteredMessages && filteredMessages.length > 0;
        });
        setChats(filteredChats);
  
        if (selectedChatGroup.length > 0) {
          const updatedChatGroup = selectedChatGroup.map(selectedChat => {
            return filteredChats.find(chat => chat.id === selectedChat.id);
          }).filter(chat => chat !== undefined) as IChat[];
        
          const newChats = filteredChats.filter(chat => {
            const hasMatchingDuty = chat.duty && chat.duty !== '' && chat.duty === selectedChatGroup[0].duty;
            const hasMatchingAd = chat.ad && chat.ad !== '' && chat.ad === selectedChatGroup[0].ad;
            const isNewChat = !selectedChatGroup.some(selectedChat => selectedChat.id === chat.id);
        
            return (hasMatchingDuty || hasMatchingAd) && isNewChat;
          });
          
          const newSelectedChatGroup = [...updatedChatGroup, ...newChats];
          
          setSelectedChatGroup(newSelectedChatGroup);
        }
      }
    }
  }, [coll]);

  useEffect(() => {
    updateTime();
  }, []);

  const updateTime = () => {
    //There are 604800000 ms in a week.
    setLatestWeek((Date.now()) - 604800000);
  };

  const groupChatsByDuty = (chatsArray: IChat[]) => {
    const groupedChats: { [key: string]: IChat[] } = {};

    chatsArray.forEach(chat => {
      const groupKey = chat.duty || chat.ad;
      if (!groupedChats[groupKey]) {
        groupedChats[groupKey] = [];
      }
      groupedChats[groupKey].push(chat);
    });

    const groupedArrays: IChat[][] = Object.values(groupedChats);

    return groupedArrays;
  };

  const groupedChats = groupChatsByDuty(chats);

  const handleSelectChatGroup = (chatArray: IChat[]) => {
    setSelectedChatGroup(chatArray);
  };

  const getLatestTimeFromChats = (chatArray: IChat[]) => {
    return chatArray[0].messages[0] ? chatArray.reduce((max, p) => p.messages[p.messages.length-1].timestamp > max ? p.messages[p.messages.length-1].timestamp : max, chatArray[0].messages[chatArray[0].messages.length-1].timestamp) : 0;
  };

  return (

    <div className='page-container'>
      <header className='header'>
        <h2 className='page-title'>Chatin valvonta</h2>
      </header>
      <div className='flex-row'>
        <div className='flex-column' style={{ marginRight: '7px' }}>
          <div style={{ overflowX: 'auto'}}>
            <h3>Dutyt/mainokset</h3>
            {groupedChats
              .sort((a, b) => {
                const latestMessageA = getLatestTimeFromChats(a);
                const latestMessageB = getLatestTimeFromChats(b);
                return latestMessageB - latestMessageA;
              })
              .map((chat, index) => {
                const latestMessageInGroup = getLatestTimeFromChats(chat);
                return (
                  <div key={index}>
                    <div
                      onClick={() => handleSelectChatGroup(chat)}
                      className={selectedChatGroup.length > 0 && selectedChatGroup.every(c => chat.includes(c)) ? 'selected-row' : (index % 2 === 0) ? 'gray-row' : 'white-row'}
                      style={{
                        width: '300px',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div><h4>{chat[0].title ? chat[0].title : 'unknown'}</h4></div>
                      <div>{chat[0].duty ? 'Duty' : 'Mainos'}</div>
                      <div>{chat[0].duty ? chat[0].duty : chat[0].ad}</div>
                      <div className='chat-timestamp' style={{ paddingTop: 1 }}>

                        {getDate(latestMessageInGroup)} {getTime(latestMessageInGroup)}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {selectedChatGroup.length > 0 &&
          <ChatList chats={selectedChatGroup}/>}
      </div>
    </div>
  );
};

export default ChatMonitor;