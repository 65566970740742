import React, { useState, useEffect } from 'react';
import { IChat } from '../../../types/types';
import { db } from '../../../firebase.config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getDate, getTime } from '../../../utils/time';
import OpenChat from '../OpenChat/OpenChat';

interface ChatListProps {
  chats: IChat[];
}
const ChatList = ({chats}: ChatListProps) => {
  const [selectedChat, setSelectedChat] = useState<IChat | null>();
  const [userNamesMap, setUserNamesMap] = useState<{ [key: string]: string }>({});
  
  useEffect(() => {
    const updatedChat = chats.find(
      (chat) => chat.id === selectedChat?.id
    );
    setSelectedChat(updatedChat);
  }, [chats]);

  useEffect(() => {
    // This needs to be changed to get the users from redux, when redux has the users.
    const fetchUserNames = async () => {
      const userIds = chats.flatMap(chat => chat.users);
      const uniqueUserIds = Array.from(new Set(userIds));

      const usersRef = collection(db, 'Users');
      const q = query(usersRef, where('uid', 'in', uniqueUserIds));
      const snapshot = await getDocs(q);

      const userNames: { [key: string]: string } = {};
      snapshot.forEach(doc => {
        const userData = doc.data() as { uid: string; generalInfo: { firstName: string; lastName: string } };
        const fullName = `${userData.generalInfo.firstName} ${userData.generalInfo.lastName}`;
        userNames[userData.uid] = fullName;
      });

      setUserNamesMap(userNames);
    };

    fetchUserNames();
  }, [chats]);

  const handleSelectChat = (chat: IChat) => {
    setSelectedChat(chat);
  };

  return(
    <div className='flex-row'>
      <div className='flex-column' style={{ marginRight: '7px' }}>
        <div>
          <h3>Keskustelut</h3>
          {chats
            .sort((a, b) => {
              const latestMessageA = a.messages.length > 0 ? a.messages[a.messages.length - 1].timestamp : 0;
              const latestMessageB = b.messages.length > 0 ? b.messages[b.messages.length - 1].timestamp : 0;
              return latestMessageB - latestMessageA;
            })
            .map((chat, index) => {
              const user1Name = userNamesMap[chat.users[0]] || '-';
              const user2Name = userNamesMap[chat.users[1]] || '-';
              const newestMessage = chat.messages[0] ? chat.messages[chat.messages.length -1].timestamp : 0;
              return (
                <div key={index}>
                  <div
                    onClick={() => handleSelectChat(chat)}
                    className={(chat==selectedChat) ? 'selected-row' : (index % 2 === 0) ? 'gray-row' : 'white-row'}
                    style={{
                      width: '220px',
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{user1Name}</div>
                    <div>{user2Name}</div>
                    <div className='chat-timestamp' style={{ paddingTop: 1 }}>

                      {getDate(newestMessage)} {getTime(newestMessage)}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {selectedChat && <OpenChat chat={selectedChat} userNamesMap={userNamesMap} />}
    </div>
  );
};

export default ChatList;