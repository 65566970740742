import React from 'react';
import { Duty } from '../../types/types';

interface DutyListItemProps {
  duty: Duty,
  index: number,
  onClick: () => void
}
const DutyListItem = ( {duty, index, onClick}: DutyListItemProps) => {
  const datePublished = new Date(duty.timestamp*1000).toLocaleString('en-GB');
  const style = index % 2 ? 'gray-row' : 'white-row';
  return (
    <tr onClick={onClick} className={`table-row ${style}`}>
      <td>
        <div style={{'display': 'flex'}}>
          {duty.reports?.length > 0 && <div className='reportedIcon'>!</div>}
          {duty.title}
        </div>
      </td>
      <td>{datePublished}</td>
      <td>{duty.price} €</td>
      <td>{duty.status}</td>
    </tr>

  );

};

export default DutyListItem;