import React, { useState } from 'react';
import { CustomerChat } from '../../../types/types';
import { db } from '../../../firebase.config';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { IMessage } from '../../../types/types';

interface MultiMessageProps {
  setCancel: () => void;
  selectedUsers: CustomerChat[];
  unselectUser: (customerId: string) => void;
}

const MultiMessage = ({ setCancel, selectedUsers, unselectUser }: MultiMessageProps) => {
  const [message, setMessage] = useState('');

  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async (message: string, chats: CustomerChat[]) => {
    const messageData: IMessage = {
      message: message,
      picture: '',
      read: 0,
      timestamp: Date.now(),
      user: 'Duty'
    };

    const promises: Promise<void>[] = [];

    chats.map((chat) => {
      const docRef = doc(db, 'CustomerService', chat.customerId);
      promises.push(updateDoc(docRef, {
        messages: arrayUnion(messageData)
      }).catch(err => console.log(err)));
    });
    await Promise.all(promises);
  };

  const handleSubmit = () => {
    if (message.trim() !== '') {
      handleSendMessage(message, selectedUsers);
      setMessage('');
    }
  };

  return (
    <div className='chat-container'>
      <h4 style={{paddingBottom:10}}>Lähetä viesti monelle</h4>
      <h5>Valitut käyttäjät:</h5>
      <div className='recipients'>{selectedUsers.length === 0 ? 'Ei valittuja' : (
        selectedUsers.map((selectedUser, index) => (
          <React.Fragment key={index}>
            <button 
              onClick={() => unselectUser(selectedUser.customerId)}
              style={{display: 'inline-flex', marginRight: '3px', marginTop: '3px'}}
            >
              {selectedUser.customerName} <span style={{color: '#c00000', marginLeft: '0.5rem'}}>X</span>
            </button>
          </React.Fragment>
        )))}
      </div>

      <textarea
        rows={4}
        cols={50}
        placeholder="Kirjoita viestisi..."
        value={message}
        onChange={handleChange}
        style={{height: 60, marginTop:20}}
      />
      <div>
        <button style={{padding: 10, marginTop: 5, marginRight: 10}} onClick={handleSubmit}>Lähetä</button>
        <button style={{padding: 10}} onClick={setCancel}>Peruuta</button>
      </div>        
    </div>
  );
};

export default MultiMessage;