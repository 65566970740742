import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

interface ButtonProps {
  text: string;
  to: string; 
}

const LandingPage: React.FC = () => {
  const Button: React.FC<ButtonProps> = ({ text, to }) => (
    <Link to={to} className='custom-button'>
      <div className='button-content'>
        <span className='button-text'>{text}</span>
      </div>
    </Link>
  );

  return (
    <div>
      <div className='button-container'>

        <Button text='Chat Valvonta' to='/ChatMonitoring' />
        <Button text='Uudet Työt' to='/NewAssigments' />
        <Button text='Käyttäjät' to='/UserImageAddition' />


        <Button text='Käyttäjien Editointi' to='UserDataEditing' />
        <Button text='Duty Coin' to='/DutyCoin' />
        <Button text='Mainokset' to='/Ads' />


        <Button text='Vanhat Työt' to='/OldAssigments' />
        <Button text='Asiakaspalvelu Chat' to='/CustomerServiceChat' />
        <Button text='Chatti Sivu' to='/ChatSite' />


      </div>
    </div>
  );
};



export default LandingPage;