import React from 'react';

interface DropdownListProps {
  handleRejectReasonChange: (event: any) => void,
  rejectedFields: any,
  field: string
}
const DropdownList = ({handleRejectReasonChange, rejectedFields, field}: DropdownListProps) => {

  const options = ['Epäasiallinen'];
  return (
    <div>
      {rejectedFields[field].rejected &&
        <select id={field} value={rejectedFields[field].reason} onChange={handleRejectReasonChange} style={{'marginRight': '5px'}}>
          {options.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      }
    </div>
  );
};

export default DropdownList;