import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Outlet } from 'react-router-dom';
import * as ROUTES from '../../Constants/routes'; // Import route paths

const Navbar = () => {
  const [user] = useAuthState(auth);

  const signOutUser = () => {
    signOut(auth).then(() => console.log('signed out'))
      .catch(err => console.log(err));
  };

  if (user) {
    return (
      <div>
        <div className='navbar'>
          <Link to={ROUTES.LANDING}> {/* Use ROUTES.LANDING for navigation */}
            <img className='duty-logo' src={require('../../assets/Logo_duty.png')} />
          </Link>
          <p className='navbar-reviewer'>reviewer</p>
          <a className='signout' href='' onClick={signOutUser}>Sign out</a>
        </div>
        <Outlet />
      </div>
    );
  }
  return (
    <div>
      <div className='navbar'>
        <Link to={ROUTES.LANDING}> {/* Use ROUTES.LANDING for navigation */}
          <img className='duty-logo' src={require('../../assets/Logo_duty.png')} />
        </Link>
        <p className='navbar-reviewer'>reviewer</p>
      </div>
      <Outlet />
    </div>
  );
};

export default Navbar;