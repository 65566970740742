import React from 'react';
import DutyList from '../../components/DutyList/DutyList';

const OldAssigments: React.FC = () => {
  return (
    <>
      <DutyList/>
    </>
  );
};

export default OldAssigments;