import React from 'react';
import styles from './AdListItem.module.css';
import { Ad } from '../../../types/types';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AdListItemProps {
  ad: Ad,
  onClick: () => void
}

const AdListItem = ({ad, onClick}: AdListItemProps) => {
  return (
    <div onClick={onClick}className={styles.container}>
      <div className={styles.imageContainer}>
        {ad.images[0] && ad.images[0].url ? <img src={ad.images[0].url} alt={ad.images[0].name || 'Ad image'} className={styles.image} /> : null}
        <div className={styles.tags}>
          <span className={styles.categoryTag}>{ad.category}</span>
        </div>
      </div>
      <div className={styles.textContainer}>
        <header className={styles.title}>{ad.title}</header>
        <p className={styles.desc}>{ad.desc}</p>
        <p className={styles.desc}>{ad.fullName}</p>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.price}>
          <p>Alk. {ad.pricing.reduce((min, p) => p.price < min ? p.price : min, ad.pricing[0].price)}€/h</p>
        </div>
        <div className={styles.address}>
          <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.addressIcon} />
          <span>{ad.location}</span>
        </div>
      </div>
    </div>
  );
};

export default AdListItem;
  