import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerChat } from '../types/types';
import { RootState } from './store';

interface customerServiceChatState {
  chats: CustomerChat[];
}

const initialState : customerServiceChatState = {
  chats: [],
};

export const customerServiceChatSlice = createSlice({
  name: 'customerServiceChat',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<Array<CustomerChat>>) => {
      state.chats = action.payload;
    },
  },
});

export const { setChats } = customerServiceChatSlice.actions;

export const selectCustomerServiceChats = (state: RootState) => state.customerServiceChats.chats;

export default customerServiceChatSlice.reducer;