export const getDate = (timestamp:number) => {

  const date = new Date(timestamp);
  const today = new Date();
  const isToday = () => {
    return date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear();
  };
  if (isToday()) return 'Tänään';

  const isYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDate() == yesterday.getDate() &&
    date.getMonth() == yesterday.getMonth() &&
    date.getFullYear() == yesterday.getFullYear();
  };
  if (isYesterday()) return 'Eilen';

  const isThisYear = () => {
    return date.getFullYear() == today.getFullYear();
  };
  const dateString = () => {
    if (isThisYear()) {
      return (
        date.getDate() + '.' + (date.getMonth() + 1) + '.'
      );
    } else {
      return (
        date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      );
    }
  };
  return dateString();

};

export const getTime = (timestamp:number) => {
  const date = new Date(timestamp);
  const time = `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`;
  return time;
};

export const getTimeStatus = (timestamp: number) => {
  const now = Date.now();
  const diffInMilliseconds = now - timestamp * 1000; // Convert seconds to milliseconds
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));

  if (diffInHours <= 1) {
    return 'Juuri nyt';
  } else if (diffInHours <= 24) {
    return `${diffInHours}h sitten`;
  } else if (diffInHours <= 48) {
    return 'Eilen';
  } else {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }
};