import React, { useEffect, useState } from 'react';
import { db } from '../../firebase.config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { IUser } from '../../types/types';

interface UserInfoProps{
  uid: string;
  boxHeight: string
}

const UserInfo = ({ uid, boxHeight }: UserInfoProps) => {
  const [user, setUser] = useState<IUser | null>();

  useEffect(() => {
    let ignore = false;
    const getUserInfo = async () => {
      const usersRef = collection(db, 'Users');
      const q = query(usersRef, where('uid', '==', uid));
      const snapshot = await getDocs(q);
        
      if (!snapshot.empty) {
        const userData = snapshot.docs[0].data() as IUser;
        setUser(userData);
      } 
      else {
        setUser(null);
      }
    };
    if (!ignore){
      getUserInfo();
    }
    return () => {
      ignore = true;
    };
  }, [uid]);

  return (
    <div className='userInfoScreen' style={{height: boxHeight}}>
      <div>
        <h4>{user?.generalInfo ? user?.generalInfo.firstName ? (user?.generalInfo.firstName) : '-' : '-'}<span style={{marginLeft: '0.5rem'}}>{user?.generalInfo ? user?.generalInfo.lastName ? (user?.generalInfo.lastName) : '-' : '-'}</span></h4>
      </div>
      <div>
        {user?.photoUrl ? (<img className='profilePhoto' src={user?.photoUrl} alt='Profiilikuva'></img>) : 'Ei kuvaa'}
      </div>
      <div>
        Uid: {uid}
      </div>
      <div>
        Kuvaus: {user?.generalInfo ? user?.generalInfo.description ? (user?.generalInfo.description) : '-' : '-'}
      </div>
      <div>
        Osoite: {user?.generalInfo ? user?.generalInfo.address ? (user.generalInfo.address) : '-' : '-'} <span style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}>{user?.generalInfo ? user?.generalInfo.postNumber ? (user.generalInfo.postNumber) : '' : ''}</span>{user?.generalInfo ? user?.generalInfo.postCity ? (user.generalInfo.postCity) : '' : ''}
      </div>
      <div>
        Sähköpostiosoite: {user?.generalInfo ? user.generalInfo.email ? (user.generalInfo.email) : '-' : '-'}
      </div>
      <div>
        Y-tunnus: {user?.payment ? user?.payment.ytunnus ? (user.payment.ytunnus) : '-' : '-'}
      </div>
      <div>
        Tehtyjä töitä: {user?.doneDuties ? (user?.doneDuties) : '0'}
      </div>
      <div>
        Ostettuja töitä: {user?.boughtDuties ? (user.boughtDuties) : '0'}
      </div>
    </div>
  );
};

export default UserInfo;