import { FirebaseOptions, initializeApp,} from 'firebase/app';
import { getAuth } from 'firebase/auth';
import 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const prodConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID
};

const stagingConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY_STAGING,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_STAGING,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_STAGING,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_STAGING,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID_STAGING,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_STAGING,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID_STAGING
};

const prConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY_PR,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PR,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PR,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PR,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID_PR,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_PR,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID_PR
};

let config: FirebaseOptions | null = null;
const deploy_env = process.env.REACT_APP_DEPLOY_ENV;

switch (deploy_env) {
case 'production':
  config = prodConfig;
  break;
case 'staging':
  config = stagingConfig;
  break;
case 'pr':
  config = prConfig;
  break;
default:
  config = prConfig;
  break;
}

const app = initializeApp(config);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'europe-west1');

export {auth, db, storage, functions};