export const LANDING = '/';
export const ADS = 'Ads';
export const CHATMONITORING = 'ChatMonitoring';
export const CHATSITE = 'ChatSite';
export const CUSTOMERSERVICECHAT = 'CustomerServiceChat';
export const DUTYCOIN = 'DutyCoin';
export const NEWASSIGNMENTS = 'NewAssigments';
export const OLDASSIGNMENTS = 'OldAssigments';
export const USERDATAEDITING = 'UserDataEditing';
export const USERIMAGEADDITION = 'UserImageAddition';
export const KAYTTAJAHAKU = '/kayttajahaku';
export const NAVBAR = 'Navbar';
