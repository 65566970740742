import React from 'react';
import { Oval } from 'react-loader-spinner';

interface SpinnerProps {
  size: number
}

const Spinner = ({size}: SpinnerProps) => {

  return (
    <Oval
      color="#ed6ea7"
      secondaryColor='#c2c2c2'
      height={size}
      width={size}
      ariaLabel="tail-spin-loading"
      strokeWidth={3}
      strokeWidthSecondary={3}
      wrapperClass=""
      visible={true}
    />
  );
};

export default Spinner;