import React from 'react';

const ChatSite: React.FC = () => {
  return (
    <>
      <h1>ChatSite</h1>
      <p>Nappi tähän</p>
    </>
  );
};

export default ChatSite;