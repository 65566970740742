import React from 'react';
import Modal from 'react-modal';
import styles from './AdModal.module.css';
import { Ad, AdPriceCard } from '../../../types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faClock } from '@fortawesome/free-solid-svg-icons';
import ImageCarousel from '../../ImageCarousel/ImageCarousel';

interface AdModalProps {
  ad: Ad,
  isOpen: boolean,
  closeModal: () => void;
}
const modalHeight = window.innerHeight - 100;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '900px',
    maxHeight: modalHeight
  },
  overlay: {
    backgroundColor: 'rgb(0, 0, 0, 0.6)'
  }
};

const AdModal = ( {ad, isOpen, closeModal}: AdModalProps) => {

  const adDate = ad && new Date(ad.timestamp*1000).toLocaleString('en-GB');
  const slice = adDate?.slice(0, 10);
  const date = slice?.replaceAll('/', '.');

  const onModalClose = () => {
    closeModal();
  };

  return (
    <div>
      {ad &&
        <Modal
          isOpen={isOpen}
          onRequestClose={onModalClose}
          style={customStyles}
          ariaHideApp={false}
        >
          <button style={{'marginBottom': '10px'}} onClick={onModalClose}>Sulje</button>
          <div className={styles.adCard}>
            {ad.images.length > 0 ? <ImageCarousel images={ad.images}/> : 'Ei kuvia'}
            <h2 className={styles.title}>{ad.title}</h2>
            <div className={styles.priceLocation}>
              <div><FontAwesomeIcon style={{'marginRight': '10px'}} icon={faLocationDot} />{ad.location}</div>
              <div><FontAwesomeIcon icon={faClock} /> Ilmoitus julkaistu {date}</div>
            </div>
            <div className={styles.description}>
              <p>{ad.desc}</p>
            </div>
            <p>{ad.fullName} ({ad.user})</p>
            <div className={styles.category}>
              <p>{ad.category}</p>
            </div>
            <div>
              Doc: {ad.doc ? ad.doc : '-'}
            </div>
            <div>
              Visible: {ad.visible ? 'True' : 'False'} <span style={{marginLeft: '0.5rem'}}></span> Review: {ad.review ? 'True' : 'False'}
            </div>
            <div>
              <table className={styles.table}>
                <thead>
                  <th>Hinnoittelu</th>
                </thead>
                <tbody>
                  {ad.pricing && ad.pricing.map((price:AdPriceCard, index:number) => (
                    <tr key={price.id}>
                      <td className='table-row'>{price.title}</td>
                      <td className='table-row'>{price.desc}</td>
                      <td className='table-row'>{price.id}</td>
                      <td className='table-row'>{price.alv} %</td>
                      <td className='table-row'>{price.price} €/h</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>        
        </Modal>}
    </div>
  );
};

export default AdModal;