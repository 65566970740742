import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { IChat } from '../../../types/types';
import Message from '../../CustomerService/ChatWindow/Message/Message';
import UserInfo from '../../UserInfo/UserInfo';


interface OpenChatProps {
  chat: IChat;
  userNamesMap: { [key: string]: string };
}
const OpenChat = ({chat, userNamesMap}: OpenChatProps) => {
  const [isWideScreen, setIsWideScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1742);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const chatContainerRef= useRef<any>();

  const scrollHandler = () => {
    const chatContainer = chatContainerRef.current;
    chatContainer?.scrollTo({
      top: chatContainer.scrollHeight,
      behavior: 'instant'
    });
  };

  useLayoutEffect(() => {
    scrollHandler();
  }, [chat.messages]);

  return(

    <div className='flex-row'>
      <div className='flex-column' style={{ marginRight: '7px' }}>
        <h3>Chat</h3>
        <div className='chat-window' ref={chatContainerRef}>
          {chat.messages.map((msg, index) => {
            const messageSentBy = userNamesMap[msg.user] || '-';
            return (
              <div key={index}>
                <Message message={msg} name={messageSentBy} altBgColor={chat.users[0]===msg.user} key={index} />
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ flexDirection: 'column'}}>
        <h3>Käyttäjätiedot</h3>
        {isWideScreen ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <UserInfo uid={chat.users[0]} boxHeight='515px'/>
            <UserInfo uid={chat.users[1]} boxHeight='515px'/>
          </div>
        ) : (
          <div>
            <UserInfo uid={chat.users[0]} boxHeight='260px'/>
            <UserInfo uid={chat.users[1]} boxHeight='260px'/>
          </div>
        )}
      </div>
    </div>
  );
};

export default OpenChat;