import React, { useEffect, useState} from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useSignInWithEmailAndPassword, useSignOut } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebase.config';

const Login = () => {

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  {/* eslint-disable-next-line  */}
  const [ signInWithEmailAndPassword, user, loading, error ] = useSignInWithEmailAndPassword(auth);
  const [signOut] = useSignOut(auth);

  const handleEmailChange = (event:any) => {
    setEmail(event.target.value);
  };
  const handlePWChange = (event:any) => {
    setPassword(event.target.value);
  };
  const handleLogin = async (event:any) => {
    event.preventDefault();
    if (!password ) return setErrorMessage('Salasana?');

    signInWithEmailAndPassword(email, password)
      .then(async(res) => {
        if (res) {
          const usersRef = collection(db, 'Users');
          const q = query(usersRef, where('reviewer', '==', true));
          const snapshot = await getDocs(q);
          const docs: any[] = [];
          snapshot.forEach(doc => {
            docs.push(doc.data());
          });
          const result = docs.find(el => el.uid === res.user.uid);

          if (result) {
            return Promise.resolve();
          }
          else {
            signOut();
            console.log('EI OIKEUKSIA!!!');
          }
        }
      }).catch(err => {
        console.log(err);
      });
  };
  const handleLoginError = () => {
    console.log(error);
    switch (error?.code) {
    case 'auth/invalid-email':
      setErrorMessage('Väärä sähköposti tai salasana.');
      break;
    case 'auth/user-not-found':
      setErrorMessage('Väärä sähköposti tai salasana.');
      break;
    case 'auth/wrong-password':
      setErrorMessage('Väärä sähköposti tai salasana.');
      break;
    case 'auth/too-many-requests':
      setErrorMessage('Liian monta sisäänkirjautumispyyntöä.');
      break;
    }
  };
  useEffect(() => {
    handleLoginError();
  }, [error]);

  return (
    <div className='container'>

      <form className='login-form' onSubmit={handleLogin}>
        <label className='form-label'>Email</label>
        <input className='form-input' type="text" value={email} onChange={handleEmailChange}/>
        <label className='form-label'>Password</label>
        <input className='form-input' type="password" value={password} onChange={handlePWChange} />
        {errorMessage && <div style={{'color': 'red'}}>{errorMessage}</div>}
        <button className='form-submit'>Log in</button>
      </form>
  
    </div>
  );
};

export default Login;
